<template>
  <div>
    <el-row type="flex">
      <el-button v-if="isManager" type="primary" @click="dialogVisible=true">上传文件</el-button>
      <el-button type="primary" @click="getPass(1)">刷新</el-button>
    </el-row>
    <el-row>
      <el-table
          border
          ref="multipleTable"
          :data="tableData.records"
          tooltip-effect="dark"
          style="width: 100%">
        <el-table-column
            prop="name"
            label="文件名">
        </el-table-column>
        <el-table-column
            prop="username"
            label="上传者"
            width="100">
        </el-table-column>
        <el-table-column
            prop="userImg"
            label="头像"
            width="120">
          <template slot-scope="scope">
            <el-avatar :src=scope.row.userImg></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
            sortable
            prop="gmtCreate"
            label="上传时间"
            width="200">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
        >
          <template slot-scope="scope">
            <el-button v-if="isManager" @click="deleteInformation(scope.row)" type="text">删除</el-button>
            <el-link v-else :href="scope.row.url" type="success">下载</el-link>
            <el-button @click.native="preview(scope.row)" type="text">在线预览</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="tableData"
          background
          :current-page="tableData.current"
          :page-size="tableData.size"
          @current-change="getPass"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total">
      </el-pagination>
    </el-row>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%">
      <el-upload
          :data="item"
          class="upload-demo"
          drag
          name="file"
          :action="url"
          :on-success="success"
          :limit="5"
          multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <!--        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import global from "@/config/global";
import {selectUploadInfo} from "@/api/uploadInfo";

export default {
  name: "information",
  props: {
    isManager: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  data() {
    return {
      url: global.uploadInfoUrl,
      item: {
        pid: this.$route.params.id,
        uid: this.$store.state.user.id,
      },
      tableData: [],
      id: this.$route.params.id,
      dialogVisible: false,
    }
  },
  mounted() {
    console.log(this.isManager)
    this.getPass(1);
  },
  methods: {
    isAssetTypeAnImage(ext) {
      return [
        'png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1;
    },
    isAssetTypeAnOffice(ext) {
      return [
        'pptx', 'ppt', 'doc', 'docx', "xls", "xlsx"].indexOf(ext.toLowerCase()) !== -1;
    },
    preview(row) {
      // console.log(row)
      let fileName = row.url;
      let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      console.log(fileExtension)
      if (fileExtension === "pdf") {
        this.$router.push({path: "/preview/pdf/", query: {url: fileName}})
      } else if (this.isAssetTypeAnOffice(fileExtension)) {
        window.open(
            "https://view.officeapps.live.com/op/view.aspx?src=" + fileName,
            "_blank"
        );
        return false
      }
    },
    success(response, file, fileList) {
      this.$message(response.data.uploadInfo)
      // console.log(fileList);
      this.dialogVisible = false;

    },
    getPass(number) {
      selectUploadInfo(this.id, number).then(result => {
        this.tableData = result.data.uploadInfo;
      }).catch(error => {
        this.$message(error.message)
      })
    },
    // 删除用户信息
    deleteInformation(item) {

    }
  },
  watch: {
    isManager: function (value) {
      console.log("information " + value)
    }
  }
}
</script>

<style scoped>

</style>